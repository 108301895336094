export default [
  {
    id: 5,
    is_update: 0,
    label: '板件成品尺寸',
    sample: '2440X1220X18',
    text: '2440X1220X18',
    value: 'oSize',
  },
  {
    id: 6,
    is_update: 0,
    label: '板件成品尺寸（仅长宽）',
    sample: '2440X1220',
    text: '2440X1220',
    value: 'oSize_L',
  },
  {
    id: 7,
    is_update: 0,
    label: '板件材质',
    sample: '多层实木',
    text: '多层实木',
    value: 'matCode',
  },
  {
    id: 8,
    is_update: 0,
    label: '大板序号',
    sample: '1',
    text: '1',
    value: 'bigPlankID',
  },
  {
    id: 9,
    is_update: 0,
    label: '第几张大板',
    sample: '第1 张大板',
    text: '第1 张大板',
    value: 'plank_index',
  },
  {
    id: 10,
    is_update: 0,
    label: '标签页码',
    sample: '第1/10页',
    text: '第1/10页',
    value: 'lable_index',
  },
  {
    id: 11,
    is_update: 0,
    label: '排版时间',
    sample: '2022/01/01 00:00:00',
    text: '2022/01/01 00:00:00',
    value: 'paibanTime',
  },
  {
    id: 12,
    is_update: 0,
    label: '板件条码',
    sample: '6160479583801',
    text: '6160479583801',
    value: 'oriPlankNum',
  },
  {
    id: 13,
    is_update: 0,
    label: '大板尺寸',
    sample: '2440*1220*18',
    text: '2440*1220*18',
    value: 'plankSize',
  },
  {
    id: 14,
    is_update: 0,
    label: '板件颜色',
    sample: 'T01',
    text: 'T01',
    value: 'texture',
  },
  {
    id: 15,
    is_update: 0,
    label: '所在余料库',
    sample: '001',
    text: '001',
    value: 'branch_no',
  },
  {
    id: 16,
    is_update: 0,
    label: '来源订单地址',
    sample: '001',
    text: '001',
    value: 'from_address',
  },
  {
    id: 17,
    is_update: 0,
    label: '来源订单号',
    sample: '001',
    text: '001',
    value: 'from_order_num',
  },
]
